import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "disaster-recovery"
    }}>{`Disaster recovery`}</h1>
    <p>{`The section describes how to perform disaster recovery testing using AutoBackup.`}</p>
    <h3 {...{
      "id": "supported-resources"
    }}>{`Supported resources`}</h3>
    <ul>
      <li parentName="ul">{`AWS RDS instances (copying a snapshot to the restore region)`}</li>
      <li parentName="ul">{`AWS RDS clusters (copying a snapshot to the restore region)`}</li>
      <li parentName="ul">{`AWS EC2 (creating an AMI in the restore region)`}</li>
      <li parentName="ul">{`Azure VM (creating a DR resource group, creating disks from Recovery Point and deploying the template)`}</li>
    </ul>
    <h3 {...{
      "id": "cleanup-after-disaster-recovery"
    }}>{`Cleanup after Disaster Recovery`}</h3>
    <p>{`AutoBackup will delete the created resources within 24 hours. If you do not want to wait you can delete them manually.
For Azure VM, AutoBackup will remove the whole resource group created for Disaster Recovery testing.
For AWS resources, AutoBackup will remove the copied snapshot to the Disaster Recovery region (the snapshots created during backup event
will be untouched). Remember to remove created CloudFormation stacks manually.`}</p>
    <h2 {...{
      "id": "add-a-new-template"
    }}>{`Add a new template`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Disaster Recovery" from the navigation bar.`}</li>
      <li parentName="ol">{`Choose "Templates" tab and click "Create new" button.
`}<img alt="Disaster Recovery Templates View" src={require("../../assets/usage/disaster-recovery/templates-list.png")} /></li>
      <li parentName="ol">{`Provide the name of the template and upload the template file (you can download example templates and adjust them for your use case)
by clicking "Choose file" button.
`}<img alt="Disaster Recovery Template Create" src={require("../../assets/usage/disaster-recovery/template-create.png")} /></li>
      <li parentName="ol">{`Click "Create template" button to store template in the AutoBackup (AutoBackup will validate the correctness of the provided template).`}</li>
    </ol>
    <h2 {...{
      "id": "add-a-new-plan"
    }}>{`Add a new plan`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Disaster Recovery" from the navigation bar.`}</li>
      <li parentName="ol">{`Choose "Plans" tab and click "Create new" button.
`}<img alt="Disaster Recovery Plans View" src={require("../../assets/usage/disaster-recovery/plans-list.png")} /></li>
      <li parentName="ol">{`Enter all required fields to the plan form.`}</li>
    </ol>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Plan name`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Custom name of plan.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Plan cloud provider`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Determines available regions, templates and resources to restore.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Restore region`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Determines region where resources should be recovered.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Start date`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Determines testing plan starting date (based on this field next testing dates will be calculated).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Test frequency (in months)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Determines frequency of testing.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of days to send reminder before the test`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Determines how many days before next test date notifications must be sent.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`KMS key`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`KMS key is used by AutoBackup to copy encrypted AWS snapshots to restore region. Following formats are correct: key ID (for example, key/1234abcd-12ab-34cd-56ef-1234567890ab), key alias (for example, alias/ExampleAlias), key ARN (for example, arn:aws:kms:us-east-1:012345678910:key/abcd1234-a123-456a-a12b-a123b4cd56ef).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Templates`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`List of templates attached to the plan (which templates should be tested in plan).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Recipients`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Determines who should be notified about upcoming test date.`}</td>
        </tr>
      </tbody>
    </table>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Click "Save" button after you've finished providing values.
`}<img alt="Disaster Recovery Plan Creation View" src={require("../../assets/usage/disaster-recovery/plan-creation-view.png")} />
        <img alt="Disaster Recovery Plan Resource Selection View" src={require("../../assets/usage/disaster-recovery/plans-resource-selection-view.png")} /></li>
    </ol>
    <h2 {...{
      "id": "start-disaster-recovery-testing"
    }}>{`Start Disaster Recovery Testing`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Disaster Recovery" from the navigation bar.`}</li>
      <li parentName="ol">{`Choose "Plans" tab and click on "three dots" on the right side of the template and select "Start DR" option.
`}<img alt="Disaster Recovery Start DR View" src={require("../../assets/usage/disaster-recovery/plans-start-dr.png")} /></li>
      <li parentName="ol">{`Pick the recovery date from the calendar (AutoBackup chooses the snapshots created on or before this date).
`}<img alt="Disaster Recovery Start DR Form" src={require("../../assets/usage/disaster-recovery/plans-start-dr-form.png")} /></li>
      <li parentName="ol">{`Click "Start" button (you will be redirected to the "Records" tab, your DR record will be `}<strong parentName="li"><em parentName="strong">{`IN PROGRESS`}</em></strong>{` status).`}<blockquote parentName="li">
          <p parentName="blockquote">{`To restore EC2, AutoBackup requires additional permissions - `}<strong parentName="p"><em parentName="strong">{`ec2:RegisterImage`}</em></strong>{`, `}<strong parentName="p"><em parentName="strong">{`ec2:DeregisterImage`}</em></strong>{` and `}<strong parentName="p"><em parentName="strong">{`ec2:CopyImage`}</em></strong>{`.`}</p>
        </blockquote></li>
    </ol>
    <p><img alt="Disaster Recovery Records List" src={require("../../assets/usage/disaster-recovery/records-list.png")} />{`
5. Wait for the status to change to `}<strong parentName="p"><em parentName="strong">{`READY`}</em></strong>{` (the copying process may take some time) and click "Show DR links" button.`}</p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Click all the generated links and use provider's creator to restore resources.
`}<img alt="Disaster Recovery Records List" src={require("../../assets/usage/disaster-recovery/records-links.png")} /></li>
      <li parentName="ol">{`Back to the AutoBackup ("Records" tab) and mark the testing event as success or failed.`}</li>
      <li parentName="ol">{`Click "Confirm" button. Remember that this operation cannot be undone (you could also add some comments about DR in the "Note" field).
`}<img alt="Disaster Recovery Records Confirmation Popup" src={require("../../assets/usage/disaster-recovery/records-confirmation-popup.png")} /></li>
      <li parentName="ol">{`Remove created stacks when the DR testing has been finished (AutoBackup cleanups copied snapshot once per day).`}</li>
    </ol>
    <h2 {...{
      "id": "downloading-audit-logs"
    }}>{`Downloading audit logs`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Disaster Recovery" from the navigation bar.`}</li>
      <li parentName="ol">{`Choose "Records" tab.`}</li>
      <li parentName="ol">{`Pick "Start date" and "End date" from calendar.`}</li>
      <li parentName="ol">{`Click "Download" button to fetch the report.
`}<img alt="Disaster Recovery Records Audit Log Download" src={require("../../assets/usage/disaster-recovery/records-download-audit-log.png")} /></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      